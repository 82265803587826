<template>
  <div class="wrapper">
    <el-dialog title="还款调整" :visible.sync="outerVisible" width="70%">
      <el-dialog width="60%" :title="title" :visible.sync="innerVisible" append-to-body>
        <!-- 调整账单金额 -->
        <div v-if="update_type == 'amount'" class="amount">
          <el-form ref="form" :model="amountData" label-width="">
            <el-form-item label="调整原因：">
              <el-radio-group v-model="amountData.modifyType">
                <el-radio :label="1">部分付款</el-radio>
                <el-radio :label="2">减免金额</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="调整金额：">
              <el-input-number
                :disabled="!amountData.modifyType"
                style="width:250px"
                v-model="amountData.modifyAmount"
                size="small"
                placeholder="请输入"
                :precision="2"
                :controls="false"
                :step="0.01"
                step-strictly
                :min="null"
              ></el-input-number>
              <span style="margin-left:20px;">元</span>
            </el-form-item>
          </el-form>
        </div>
        <!-- 调整账单日 -->
        <div v-if="update_type == 'date'" class="date">
          <el-form ref="form" :model="billDateData" label-width="130px">
            <el-form-item label="当期账单日：">
              {{ currentBill.billDate }}
            </el-form-item>
            <el-form-item label="新账单日选择：">
              <el-date-picker
                style="width:250px"
                v-model="billDateData.modifyDate"
                size="small"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <span style="color:#f56c6c;margin-left:15px;">（ 注意：只能选当天及以后日期 ）</span>
            </el-form-item>
            <el-form-item label="账单调整手续费：">
              <el-input-number
                style="width:250px"
                v-model="billDateData.commissionAmount"
                size="small"
                placeholder="请输入"
                :precision="2"
                :controls="false"
                :step="0.01"
                step-strictly
                :min="null"
              ></el-input-number>
              <span style="margin-left:20px;">元</span>
              <div style="color:#f56c6c;">（ 注意：手续费线下收取，请确认收款金额再进行录入 ）</div>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmUpdate">确 定</el-button>
        </div>
      </el-dialog>

      <div class="main-box">
        <div class="main" v-for="(item, i) in reduce_list" :key="i">
          <el-descriptions style="margin-bottom:20px;" :title="`订单号：${item.orderNo}`" direction="vertical" :column="8" border>
            <el-descriptions-item label="商品描述" label-class-name="my-label">
              {{ item.orderGoods.skuTitle }}
              <el-tag v-if="item.enableAlipayFundAuth" type="danger" size="mini" style="margin-left: 5px">免押</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="规格" label-class-name="my-label">
              <div v-for="(sku, i) in item.orderGoods.skuAttrList" :key="i">{{ sku.group }}： {{ sku.value }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="数量" label-class-name="my-label">{{ item.totalOrderNum }}</el-descriptions-item>
            <el-descriptions-item label="总租金" label-class-name="my-label">{{ item.rentPrice }}</el-descriptions-item>
            <el-descriptions-item label="租期" label-class-name="my-label">{{ item.billPeriodNum }}期</el-descriptions-item>
            <el-descriptions-item label="买断尾款" label-class-name="my-label">{{ item.buyoutPrice }}</el-descriptions-item>
          </el-descriptions>

          <div class="list">
            <tp-table :tableData="item.billList" :columns="columns" :showPagination="false" />
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="outerVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { handleOrderBillOffAmountAPI, getOrderBillListAPI, updateBillAmountAPI, updateBillDateAPI } from './api'
const columns = [
  {
    label: '期数',
    prop: 'billNum',
    minWidth: '60',
    customRender(h, row) {
      return <p>{row['billNum']}</p>
    }
  },
  {
    label: '账期',
    prop: 'billDate',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['billDate']}</p>
    }
  },
  {
    label: '金额',
    prop: 'principalAmount',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['principalAmount']}</p>
    }
  },
  {
    label: '逾期金额',
    prop: 'overdueAmount',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['overdueAmount']}</p>
    }
  },
  {
    label: '减免金额',
    prop: 'offAmount',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['offAmount']}</p>
    }
  },
  {
    label: '待结金额',
    prop: 'leftPayAmount',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['leftPayAmount']}</p>
    }
  },
  {
    label: '实付金额',
    prop: 'paidAmount',
    minWidth: '110',
    customRender(h, row) {
      return <p>{row['paidAmount']}</p>
    }
  },
  {
    label: '状态',
    prop: 'statusName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['statusName']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '200',
    fixed: 'right',
    customRender(h, row) {
      let timer = new Date()
      let currDate = `${timer.getFullYear().toString().padStart(4, '0')}-${(timer.getMonth() + 1).toString().padStart(2, '0')}-${timer.getDate().toString().padStart(2, '0')}`
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.finance_count_list_amount) || (row['enableAlipayFundAuth'] == true && row['billDate'] <= currDate)}
            underline={false}
            type="primary"
            onClick={() => this.updateBillAmount(row)}
          >
            账单金额调整
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.finance_count_list_date) || (row['enableAlipayFundAuth'] == true)}
            style="margin:0 10px;"
            underline={false}
            type="primary"
            onClick={() => this.updateBillDate(row)}
          >
            账单日调整
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'ReducePay',
  props: ['orderNo'],
  mixins: [authBtnMixin],
  data() {
    return {
      outerVisible: true,
      innerVisible: false,
      columns,
      update_type: '', //修改类型
      title: '',
      amountData: {
        //调整金额
        billNo: '',
        modifyType: '',
        modifyAmount: ''
      },
      billDateData: {
        //调整日期
        billNo: '',
        modifyDate: '',
        commissionAmount: ''
      },
      currentBill: {}, //当前选中的账单
      reduce_list: [],
      pickerOptions: {
        disabledDate(time) {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24)
          return time.getTime() < date
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    max() {
      // leftPayAmount
      const { modifyType } = this.amountData
      const { leftPayAmount } = this.currentBill
      if (modifyType == 2 && leftPayAmount) {
        return (Number(leftPayAmount) * 100 - 1) / 100
      } else if (modifyType == 1 && leftPayAmount) {
        return Number(leftPayAmount)
      } else {
        return null
      }
    }
  },
  created() {
    // this.getOrderBillList()
  },
  mounted() {},

  methods: {
    updateBillAmount(row) {
      this.currentBill = row
      this.amountData.billNo = row.billNo
      this.amountData.modifyType = ''
      this.amountData.modifyAmount = ''
      this.update_type = 'amount'
      this.title = '账单金额调整'
      this.innerVisible = true
    },
    updateBillDate(row) {
      this.billDateData.billNo = row.billNo
      this.billDateData.modifyDate = ''
      this.billDateData.commissionAmount = ''
      this.currentBill = row
      this.update_type = 'date'
      this.title = '账单日调整'
      this.innerVisible = true
    },
    confirmUpdate() {
      const { update_type } = this
      if (update_type == 'amount') {
        const { billNo, modifyType, modifyAmount } = this.amountData
        const data = { billNo, modifyType, modifyAmount: modifyAmount || 0 }
        updateBillAmountAPI(data).then(() => {
          this.$message.success('操作成功')
          this.innerVisible = false
          this.getOrderBillList()
          if (modifyType == 1) {
            this.$emit('update')
          }
        })
      }
      if (update_type == 'date') {
        const { billNo, modifyDate, commissionAmount } = this.billDateData
        const data = { billNo, modifyDate, commissionAmount: commissionAmount || 0 }
        updateBillDateAPI(data).then(() => {
          this.$message.success('操作成功')
          this.innerVisible = false
          this.getOrderBillList()
        })
      }
    },
    async getOrderBillList() {
      this.reduce_list = await getOrderBillListAPI(this.orderNo.join())
    },
    save() {
      const newList = this.list.filter(item => item.status != 2)
      const resultData = {
        orderNo: this.orderNo,
        list: newList.map(item => {
          return {
            billNo: item.billNo,
            offAmount: item.offAmount
          }
        })
      }

      this.$confirm('您是否确定减免?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      })
        .then(() => {
          handleOrderBillOffAmountAPI(resultData).then(() => {
            this.$message.success('操作成功')
            this.getOrderBillList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    cancel() {
      this.outerVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .my-label {
    color: #1a1e25;
    background: #fafafa;
  }
  .main-box {
    max-height: 600px;
    overflow-y: scroll;
    .main {
      margin-bottom: 20px;
    }
  }
}
</style>
